import EnvProvider from "@tt/vue-environment/EnvProvider";
import baseApiService from "@tt/vue-components/src/services/baseApiService";
import axios from "axios";
import store from "@/store";
import router from "vue-router";

const apiClient = axios.create({
  baseURL: EnvProvider.value("PROCESSMANAGER_URL"),
  headers: {
    'Accept-Language': localStorage.locale ?? "en"
  }
});

// JWT interceptor
if (EnvProvider.value("ENABLE_AUTH") === "1") {
  apiClient.interceptors.request.use(function (config) {
    if (config.url !== "user/verify") {
      config.headers["x-entity-user"] = store.getters["auth/entityUser"];
    }
    return config;
  });
}

apiClient.interceptors.response.use(
    function (response) {
      if (response.data) {
        if (Array.isArray(response.data['hydra:member']) === true) {
          let data = {
            items: [],
            totalItems: 0
          }

          data.items = response.data['hydra:member'];
          data.totalItems = response.data['hydra:totalItems'];
          return data;
        } else {
          return response.data;
        }
      }
      return response;
    },
    function (error) {
      if (error.name === "AxiosError") {
        return Promise.reject(error.message);
      }

      if (error.response) {
        if (error.response.status === 404 || error.response.status === 401) {
          return router.push({
            name: error.response.status.toString()
          });
        }

        if (error.response.status === 405) {
          let err = {};
          err["hydra:description"] = error.response.data.detail;
          return Promise.reject(err);
        }

        return Promise.reject(error.response.data);
      }
    }
)

const DownloadService = {
  printLists: {
    ...baseApiService(apiClient, 'print_lists'),
    print: async () => {
      let url = "print";
      return await apiClient.get(url, {
        responseType: "blob",
      });
    },
    download: async (id) => {
      let url = "print_lists/" + id + '/download';
      return await apiClient.get(url, {
        responseType: "blob",
      });
    }
  },
  printItems: {
    ...baseApiService(apiClient, 'print_items'),
  }
}

export default DownloadService;
